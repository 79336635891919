import { isDefined } from "@libs/utils/types";

/**
 * Used to dedupe items in an array based on a given property
 * @param arr The array to be made unique
 * @param getKey A function that returns the unique key for each item in the array
 * @returns array with items removed that had the same key from getKey.
 */
export const uniqueBy = <T>(arr: T[], getKey: (item: T) => string | number) => {
  const seen = new Set();

  return arr.filter((item) => {
    const k = getKey(item);

    return seen.has(k) ? false : seen.add(k);
  });
};

/**
 * Groups an array by a specific field's value.
 * @param array The array of the map by.
 * @param field The field to group by.
 * @returns An object where each key is the value of the field in the array.
 */
export const toMap = <T extends Record<K, PropertyKey>, K extends keyof T>(
  array: T[],
  field: K
): Record<string | number, T | undefined> =>
  array.reduce(
    (accum, value) => {
      const groupByValue = value[field];

      accum[groupByValue] = value;

      return accum;
    },
    {} as Record<T[K], T | undefined>
  );

/**
 * Used to retrieve the first item in an array
 * @param arr The array to retrieve item from
 * @returns first item in array or undefined if empty.
 */
export const getFirstItem = <T>(arr?: T[]) => {
  return arr?.[0];
};

export const findLastIndex = <T>(arr: T[], predicate: (item: T) => boolean) => {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (predicate(arr[i] as T)) {
      return i;
    }
  }

  return -1;
};

/**
 * Maps each item in an array using a mapping function, then filters out any null or undefined results.
 * An efficient way to map and filter in a single pass.
 * @template T The type of the items in the input array.
 * @template U The type of the items in the output array.
 * @param {T[]} arr - The array to map and filter.
 * @param {(item: T, index: number) => U | null | undefined} map - The mapping function, which should return a value of type U, or null or undefined to filter out an item.
 * @returns {U[]} Returns a new array containing the mapped and filtered items.
 */
export const filterMap = <T, U>(arr: T[], map: (item: T, index: number) => U | null | undefined) => {
  const mapped: U[] = [];

  for (const [index, item] of arr.entries()) {
    const mappedItem = map(item, index);

    if (isDefined(mappedItem)) {
      mapped.push(mappedItem);
    }
  }

  return mapped;
};

export type Some<T> = [T, ...T[]];

/*
  Chose 10 as an arbitrary limit for how many items you can assert
  are in an array. This is a reasonable limit for most use cases.
  If count test is less than 1 or greater than 10 items, the type
  will just be an array of the item type.
 */

export type NItems<T> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  1: [T];
  2: [T, T];
  3: [T, T, T];
  4: [T, T, T, T];
  5: [T, T, T, T, T];
  6: [T, T, T, T, T, T];
  7: [T, T, T, T, T, T, T];
  8: [T, T, T, T, T, T, T, T];
  9: [T, T, T, T, T, T, T, T, T];
  10: [T, T, T, T, T, T, T, T, T, T];
  /* eslint-enable @typescript-eslint/naming-convention */
};

type NOrMoreItems<T> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  1: [T, ...T[]];
  2: [T, T, ...T[]];
  3: [T, T, T, ...T[]];
  4: [T, T, T, T, ...T[]];
  5: [T, T, T, T, T, ...T[]];
  6: [T, T, T, T, T, T, ...T[]];
  7: [T, T, T, T, T, T, T, ...T[]];
  8: [T, T, T, T, T, T, T, T, ...T[]];
  9: [T, T, T, T, T, T, T, T, T, ...T[]];
  10: [T, T, T, T, T, T, T, T, T, T, ...T[]];
  /* eslint-enable @typescript-eslint/naming-convention */
};

type HasNItems<T, C extends number> = C extends keyof NItems<T> ? NItems<T>[C] : T[];
type HasNOrMoreItems<T, C extends number> = C extends keyof NOrMoreItems<T> ? NOrMoreItems<T>[C] : T[];

export const hasItems = <T>(arr: T[]): arr is Some<T> => {
  return arr.length > 0;
};

export const hasNItems = <T, C extends number>(arr: T[], count: C): arr is HasNItems<T, C> => {
  return arr.length === count;
};

export const hasNOrMoreItems = <T, C extends number>(arr: T[], count: C): arr is HasNOrMoreItems<T, C> => {
  return arr.length >= count;
};

export const reverseForEach = <T>(arr: T[], callback: (item: T, index: number) => void) => {
  for (let i = arr.length - 1; i >= 0; i--) {
    callback(arr[i] as T, i);
  }
};
